<template>

  <a-drawer
    :title="title"
    :maskClosable="true"
    :width="drawerWidth"
    placement="right"
    :closable="true"
    @close="handleCancel"
    :visible="visible"
  >

    <template slot="title">
      <div style="width: 100%;">
        <span>{{ title }}</span>
        <span style="display:inline-block;width:calc(100% - 51px);padding-right:10px;text-align: right">
          <a-button
            @click="toggleScreen"
            icon="appstore"
            style="height:20px;width:20px;border:0px"
          >
          </a-button>
        </span>
      </div>

    </template>

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item
          label="用户名(账号)"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[ 'username']"
            disabled
          />
        </a-form-item>

        <!-- <template v-if="!model.id">
          <a-form-item label="登录密码"
                       :labelCol="labelCol"
                       :wrapperCol="wrapperCol">
            <a-input type="password"
                     placeholder="请输入登录密码"
                     v-decorator="[ 'password', validatorRules.password]" />
          </a-form-item>

          <a-form-item label="确认密码"
                       :labelCol="labelCol"
                       :wrapperCol="wrapperCol">
            <a-input type="password"
                     @blur="handleConfirmBlur"
                     placeholder="请重新输入登录密码"
                     v-decorator="[ 'confirmpassword', validatorRules.confirmpassword]" />
          </a-form-item>
        </template> -->

        <a-form-item
          label="姓名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[ 'realname']"
            disabled
          />
        </a-form-item>

        <a-form-item
          label="用户角色"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-select
            mode="tags"
            style="width: 100%"
            v-decorator="[ 'roleId', validatorRules.roleId ] "
            placeholder="请输入用户角色"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item,i) in userGroup"
              :value="item.id"
              :key="(i + 9).toString(36) + i"
            >
              {{item.roleName}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <!-- <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="[ 'email', validatorRules.email]" disabled />
        </a-form-item>

        <a-form-item label="手机号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="[ 'phone', validatorRules.phone]" disabled />
        </a-form-item> -->

      </a-form>
    </a-spin>
    <depart-window
      ref="departWindow"
      @ok="modalFormOk"
    ></depart-window>

    <div
      class="drawer-bootom-button"
      v-show="!disableSubmit"
    >
      <a-popconfirm
        title="确定放弃编辑？"
        @confirm="handleCancel"
        okText="确定"
        cancelText="取消"
      >
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button
        @click="handleSubmit"
        type="primary"
        :loading="confirmLoading"
      >提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import pick from 'lodash.pick'

// 引入搜索部门弹出框的组件
import DepartWindow from './DepartWindow'
import api from './user'
import roleApi from '../role/api'

export default {
  name: 'RoleModal',
  components: {
    DepartWindow
  },
  data () {
    return {
      positionDep: [],
      // 获取当前选中的部门id 保存时查询相应的角色
      positionDeptId: '',
      userGroup: [],
      departDisabled: false, // 是否是我的部门调用该页面
      modalWidth: 800,
      drawerWidth: 700,
      modaltoggleFlag: true,
      confirmDirty: false,
      selectedDepartKeys: [], // 保存用户选择部门id
      checkedDepartKeys: [],
      checkedDepartNames: [], // 保存部门的名称 =>title
      checkedDepartNameString: '', // 保存部门的名称 =>title
      userId: '', // 保存用户id
      disableSubmit: false,
      userDepartModel: { userId: '', departIdList: [] }, // 保存SysUserDepart的用户部门中间表数据需要的对象
      dateFormat: 'YYYY-MM-DD',
      validatorRules: {
        username: {
          rules: [{
            required: true,
            message: '请输入用户名!'
          }, {
            validator: this.validateUserCode
          }]
        },
        password: {
          rules: [{
            required: true,
            message: '请输入密码!'
          }, {
            validator: this.validateToNextPassword
          }]
        },
        confirmpassword: {
          rules: [{
            required: true, message: '请重新输入登录密码!'
          }, {
            validator: this.compareToFirstPassword
          }]
        },
        roleId: { rules: [{ required: true, message: '请选择角色!' }] },
        realname: { rules: [{ required: true, message: '请输入姓名!' }] },
        phone: { rules: [{ validator: this.validatePhone }] },
        email: {
          rules: [{
            validator: this.validateEmail
          }]
        },
        roles: {}
      },
      title: '操作',
      visible: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      uploadLoading: false,
      confirmLoading: false,
      headers: {},
      form: this.$form.createForm(this),
      roleList: []
    }
  },

  computed: {
  },
  methods: {
    // 获取用户类型
    getUserGroup () {
      roleApi.queryall().then((res) => {
        if (res.status === 200) {
          this.userGroup = res.data
        }
      })
    },
    handleChange (value) {
      this.roleList = value
    },
    // 窗口最大化切换
    toggleScreen () {
      if (this.modaltoggleFlag) {
        this.modalWidth = window.innerWidth
      } else {
        this.modalWidth = 800
      }
      this.modaltoggleFlag = !this.modaltoggleFlag
    },
    refresh () {
      this.selectedDepartKeys = []
      this.checkedDepartKeys = []
      this.checkedDepartNames = []
      this.checkedDepartNameString = ''
      this.userId = ''
    },
    add (key) {
      this.positionDep = key
      this.positionDeptId = key.key
      setTimeout(() => {
        this.getUserGroup()
      }, 800)
      this.picUrl = ''
      this.refresh()
      this.edit({})
    },
    edit (record) {
      setTimeout(() => {
        this.getUserGroup()
      }, 800)
      this.resetScreenSize() // 调用此方法,根据屏幕宽度自适应调整抽屉的宽度
      const that = this
      that.checkedDepartNameString = ''
      that.form.resetFields()
      that.userId = record.id
      that.visible = true
      that.model = Object.assign({}, record)
      that.model.roleId = that.model.roleId.split(',')
      that.$nextTick(() => {
        that.form.setFieldsValue(pick(this.model, 'username', 'realname', 'roleId', 'email', 'phone'))
      })
      // 调用查询用户对应的部门信息的方法
      that.checkedDepartKeys = []
      that.loadCheckedDeparts()
    },
    //
    loadCheckedDeparts () {
      const that = this
      if (that.userId) {
        api.userWithDepart({ id: that.userId }).then((res) => {
          that.checkedDepartNames = []
          for (let i = 0; i < res.data.length; i++) {
            that.checkedDepartNames.push(res.data[i].title)
            this.checkedDepartNameString = this.checkedDepartNames.join(',')
            that.checkedDepartKeys.push(res.data[i].key)
          }
          that.userDepartModel.departIdList = that.checkedDepartKeys
        })
      } else {
        this.checkedDepartNameString = this.positionDep.title
        this.checkedDepartKeys.push(this.positionDep.key)
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.disableSubmit = false
      this.userDepartModel = { userId: '', departIdList: [] }
      this.checkedDepartNames = []
      this.checkedDepartNameString = ''
      this.checkedDepartKeys = []
      this.selectedDepartKeys = []
    },
    handleSubmit () {
      const that = this
      // 触发表单验证
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true
          const formData = Object.assign(this.model, values)
          let obj
          if (!this.model.id) {
            formData.id = this.userId
            formData.deptId = this.positionDeptId
            formData.roleList = this.roleList
            obj = api.addUser(formData)
          } else {
            obj = api.editUser(formData)
          }
          obj.then((res) => {
            that.$message.success(res.message)
            that.$emit('ok')
          }).finally(() => {
            that.confirmLoading = false
            that.checkedDepartNames = []
            that.userDepartModel.departIdList = { userId: '', departIdList: [] }
            that.close()
          })
          that.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.close()
    },
    validateToNextPassword (rule, value, callback) {
      const form = this.form
      const confirmpassword = form.getFieldValue('confirmpassword')

      if (value && confirmpassword && value !== confirmpassword) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('两次输入的密码不一样！')
      }
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true })
      }
      callback()
    },
    compareToFirstPassword (rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('两次输入的密码不一样！')
      } else {
        callback()
      }
    },
    validatePhone (rule, value, callback) {
      if (!value) {
        callback()
      } else {
        if (new RegExp(/^1[3|4|5|7|8][0-9]\d{8}$/).test(value)) {
          callback()
        } else {
          // eslint-disable-next-line standard/no-callback-literal
          callback('请输入正确格式的手机号码!')
        }
      }
    },
    validateEmail (rule, value, callback) {
      if (!value) {
        callback()
      } else {
        // eslint-disable-next-line no-useless-escape
        if (new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
          callback()
        } else {
          // eslint-disable-next-line standard/no-callback-literal
          callback('请输入正确格式的邮箱!')
        }
      }
    },
    validateUserCode (rule, value, callback) {
      if (this.model.id !== '') {
        callback()
      } else {
        var params = {
          userCode: this.form.getFieldsValue().userCode
        }
        api.checkOnlyUser(params).then((res) => {
          callback()
        })
      }
    },
    handleConfirmBlur (e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    // 搜索用户对应的部门API
    onSearch () {
      this.$refs.departWindow.add(this.checkedDepartKeys, this.userId)
    },
    // 获取用户对应部门弹出框提交给返回的数据
    modalFormOk (formData) {
      this.checkedDepartNames = []
      this.selectedDepartKeys = []
      this.checkedDepartNameString = ''
      this.userId = formData.userId
      this.userDepartModel.userId = formData.userId
      for (let i = 0; i < formData.departIdList.length; i++) {
        this.selectedDepartKeys.push(formData.departIdList[i].key)
        this.checkedDepartNames.push(formData.departIdList[i].title)
        this.checkedDepartNameString = this.checkedDepartNames.join(',')
      }
      this.userDepartModel.departIdList = this.selectedDepartKeys
      this.checkedDepartKeys = this.selectedDepartKeys // 更新当前的选择keys
    },
    // 根据屏幕变化,设置抽屉尺寸
    resetScreenSize () {
      const screenWidth = document.body.clientWidth
      if (screenWidth < 500) {
        this.drawerWidth = screenWidth
      } else {
        this.drawerWidth = 700
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 49px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.ant-table-tbody .ant-table-row td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.drawer-bootom-button {
  position: absolute;
  bottom: -8px;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 2px 2px;
}
</style>

import { post, get, deletes } from 'common/request'

export default {
  save: args => post('/sys/role/save/', args),
  list: args => get('/role/queryList', args),
  get: args => get('/sys/role/get/', args),
  add: params => post('/role/add', params),
  del: args => deletes(`/role/del?id=${args}`),
  duplicateCheck: params => get('/sys/role/getRoleByCode', params),
  duplicate: params => get('/sys/duplicate/check', params), // 重复校验
  queryall: params => get('/role/queryall', params),
  addRole: params => post('/sys/role/add', params),
  editRole: params => post('/role/update', params),
  ajaxGetDictItems: (code, params) => get(`/sys/dict/getDictItems/${code}`, params),
  queryTreeListForRole: params => get('/role/queryTreeList', params),
  queryRolePermission: params => get('/permission/queryRolePermission', params),
  saveRolePermission: params => post('/permission/saveRolePermission', params)
}

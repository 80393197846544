import { post, get, deletes } from 'common/request'

export default {
  del: args => deletes('/user/del', args, 'portal'),
  save: args => post('/user/save', args, 'portal'),
  roleList: args => get('/role/queryList', args, 'portal'),
  list: args => get('/user/list', args, 'portal'),
  listCheck: args => get('/user/queryList', args),
  queryDepartTreeList: (params) => get('/dept/treeList', params),
  searchByKeywords: (params) => get('/dept/searchBy', params, 'portal'),
  // 根据部门ID查询用户信息
  userWithDepart: (params) => get('/user/queryById', params),
  changPassword: (params) => post('/user/reset-pwd', params),
  checkrples: (params) => post('/user/addSysUserRole', params),
  grant: args => get('/sys/user/generateUserId', args, 'portal'),
  addUser: (params) => post('/user/save', params),
  editUser: (params) => post('/user/update', params),
  resetPassword: (params) => post('/user/resetPassword', params)
}
